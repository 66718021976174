import * as stepsConfig from '@/configs/steps'
import { pickRandom } from '@/helpers/pickRandom'

export async function createRandomAttributes(gender, style, ignoreAttributes) {
  const nameSuggestions = (await import('@/configs/nameSuggestions')).default

  const randomModel = {
    customPersonality: null,
  }

  let randomModelSteps

  if (gender == 'female') {
    if (style == 'realistic') {
      randomModelSteps = stepsConfig.stepsFemaleRealistic
    } else if (style == 'anime') {
      randomModelSteps = stepsConfig.stepsFemaleAnime
    }
  } else {
    if (style == 'realistic') {
      randomModelSteps = stepsConfig.stepsMaleRealistic
    } else if (style == 'anime') {
      randomModelSteps = stepsConfig.stepsMaleAnime
    }
  }

  for (const step of randomModelSteps) {
    if (!ignoreAttributes.includes(step.modifies)) {
      if (
        step.type == 'image-radio' ||
        step.type == 'icon-radio' ||
        step.type == 'custom-icon-radio'
      ) {
        const options = [...step.options]

        if (step.allowEmpty) options.push({ value: '' })

        randomModel[step.modifies] = pickRandom(options).value
      } else if (step.type == 'custom-icon-select') {
        const options = [...step.options]

        if (step.allowEmpty) options.push({ value: '' })

        const choice = pickRandom(options).value

        if (!choice) {
          randomModel[step.modifies] = []
        } else {
          randomModel[step.modifies] = [choice]
        }
      } else if (step.type == 'voice-radio') {
        const voiceOptions = step.options.filter(
          (voice) =>
            voice.startAge <= randomModel.age &&
            voice.endAge >= randomModel.age &&
            voice.ethnicities.includes(randomModel.ethnicity),
        )
        randomModel[step.modifies] = pickRandom(voiceOptions).value
      }
    }
  }

  randomModel.name = pickRandom(
    style === 'anime'
      ? nameSuggestions[gender]['asian']
      : nameSuggestions[gender][randomModel.ethnicity],
  )

  return randomModel
}
