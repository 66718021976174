<template>
  <DiscountPopup
    v-if="discountPopupOpen || (user && !user.subscription && user.cardLast4)"
    @update:open="discountPopupOpenUpdated"
  ></DiscountPopup>
  <navigation-menu v-if="!warn.banned" />
  <router-view v-if="!warn.banned" />
  <p
    class="flex justify-center items-center text-2xl font-bold h-full"
    v-if="warn.banned"
  >
    This service is no longer available
  </p>
  <Popup
    v-if="warn.warned"
    :open="warn.warned"
    @update:open="warn.toggleWarned"
    popupStyle="w-4/5 h-1/2"
  >
    <div class="flex justify-center items-center text-2xl font-bold h-full">
      <h2 class="pt-6">You've been warned</h2>
    </div>
  </Popup>
  <SubscribePopup
    v-if="user && !user.subscription"
    class="sticky z-50"
    :open="subscribePopupOpen"
    :text="subscribePopupText"
    @update:open="subscribePopupOpenUpdated"
  ></SubscribePopup>
  <LoginRegisterPopup
    :open="loginRegisterPopupOpen"
    @update:open="loginRegisterPopupOpenUpdated"
  />
  <NoBalancePopup
    :open="noBalancePopupOpen"
    @update:open="noBalancePopupOpenUpdated"
  />
  <Popup
    :open="genericPopupOpen"
    @update:open="genericPopupOpenUpdated"
    popupStyle="bg-[#0A0D22] w-[625px] pt-[50px] pb-5 px-[30px] lg:py-[75px] mx-4 lg:mx-0 lg:px-[122px]"
  >
    <div class="px-1 py-2 md:px-4 md:py-5 sm:p-6">
      <div class="flex flex-col items-center gap-[25px]">
        <h2 class="text-[30px] lg:text-[35px]">
          {{ genericPopupTitle }}
        </h2>
        <p class="text-[#B1B5DB] text-center">
          {{ genericPopupContent }}
        </p>
      </div>
    </div>
  </Popup>
</template>

<script setup>
import NavigationMenu from '@/components/NavigationMenu.vue'
import SubscribePopup from '@/components/SubscribePopup.vue'
import DiscountPopup from '@/components/DiscountPopup.vue'
import LoginRegisterPopup from '@/components/LoginRegisterPopup.vue'
import { useHead } from '@unhead/vue'
import { computed, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useWarnStore } from '@/stores/warn'
import Popup from '@/components/Popup.vue'
import NoBalancePopup from '@/components/NoBalancePopup.vue'

const $router = useRouter()
const $route = useRoute()
const $store = useStore()

const warn = useWarnStore()

const discountPopupOpen = computed(() => $store.state.discountPopupOpen)
const mobileMenuOpen = computed(() => $store.state.mobileMenuOpen)

watch(
  mobileMenuOpen,
  () => {
    if (mobileMenuOpen.value) {
      console.log(mobileMenuOpen)
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  },
  { deep: true },
)

function discountPopupOpenUpdated(val) {
  $store.commit('SET_DISCOUNT_POPUP', {
    open: val,
  })
}

const user = computed(() => $store.state.user)

const loginRegisterPopupOpen = computed(
  () => $store.state.loginRegisterPopupOpen,
)

function loginRegisterPopupOpenUpdated(val) {
  $store.commit('SET_LOGIN_REGISTER_POPUP_OPEN', {
    open: val,
  })
}

const subscribePopupOpen = computed(() => $store.state.subscribePopupOpen)
const subscribePopupText = computed(() => $store.state.subscribePopupText)
function subscribePopupOpenUpdated(val) {
  $store.commit('SET_SUBSCRIBE_POPUP', {
    open: val,
  })
}

const noBalancePopupOpen = computed(() => $store.state.noBalancePopupOpen)
function noBalancePopupOpenUpdated(val) {
  $store.commit('SET_NO_BALANCE_POPUP', {
    open: val,
  })
}

const genericPopupOpen = computed(() => $store.state.genericPopupOpen)
const genericPopupTitle = computed(() => $store.state.genericPopupTitle)
const genericPopupContent = computed(() => $store.state.genericPopupContent)
function genericPopupOpenUpdated(val) {
  $store.commit('SET_GENERIC_POPUP', {
    open: val,
  })
}

useHead({
  link: () => [
    {
      rel: 'canonical',
      href: `https://${import.meta.env.VITE_DOMAIN_NAME}${$router.resolve($route.name ? { name: $route.name } : $route).path}`,
    },
  ],
})
</script>

<style>
/* Your styles for App.vue go here */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}

*::-webkit-scrollbar-corner,
*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(85, 85, 85);
  border-radius: 15px;
}
</style>
