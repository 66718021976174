import { defineStore, acceptHMRUpdate } from 'pinia'

export const useWarnStore = defineStore('warn', {
  state: () => ({
    banned: localStorage['warn.banned'] || false,
    warned: localStorage['warn.warned'] || false,
    bannableOffenses: localStorage['warn.bannableOffenses'] || 0,
  }),
  actions: {
    toggleBanned(value) {
      this.banned = value
    },
    toggleWarned(value) {
      this.warned = value
    },
    setBannableOffenses(value) {
      this.bannableOffenses = value
    },
  },
  persist: {
    storage: localStorage,
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWarnStore, import.meta.hot))
}
