import debounce from 'debounce'
import { watch, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { encryptURIComponent, decryptURIComponent } from '@/helpers/uriEncrypt'

function isEmpty(value) {
  if (value === null) {
    return true
  }

  if (value === undefined) {
    return true
  }

  if (value === '') {
    return true
  }

  if (Array.isArray(value) && value.length === 0) {
    return true
  }

  if (typeof value === 'object' && Object.keys(value).length === 0) {
    return true
  }

  return false
}

export function useUpdateQuery({ variables, onLoad, onUpdate }) {
  const $route = useRoute()
  const $router = useRouter()

  const queryNames = Object.keys(variables)
  const variableObjects = queryNames.map((key) => variables[key])

  watch(
    variableObjects,
    debounce((newVariables) => {
      nextTick(() => {
        if ($route.path !== '/image-generator' && $route.path !== '/chat')
          return

        const newQuery = {
          ...$route.query,
        }

        for (let i = 0; i < newVariables.length; i++) {
          if (isEmpty(newVariables[i])) {
            if (Object.hasOwn(newQuery, queryNames[i])) {
              delete newQuery[queryNames[i]]
            }
            continue
          }

          if (typeof newVariables[i] === 'object') {
            newQuery[queryNames[i]] = JSON.stringify(newVariables[i])
          } else {
            newQuery[queryNames[i]] = newVariables[i]
          }

          newQuery[queryNames[i]] = encryptURIComponent(newQuery[queryNames[i]])
        }

        $router
          .replace({
            query: newQuery,
          })
          .then(() => onUpdate && onUpdate())
      })
    }, 1000),
    { immediate: true },
  )

  onLoad(
    queryNames.reduce((acc, key) => {
      const value = $route.query[key]
      if (isEmpty(value)) {
        return acc
      }

      acc[key] = decryptURIComponent(value)

      return acc
    }, {}),
  )
}
