import { defineStore, acceptHMRUpdate } from 'pinia'

export const useChatStore = defineStore('chat', {
  state: () => ({
    voiceMessageToggle: false,
  }),
  actions: {
    disableVoiceMessage() {
      this.voiceMessageToggle = false
    },
    toggleVoiceMessage() {
      this.voiceMessageToggle = !this.voiceMessageToggle
    },
  },
  persist: {
    paths: ['voiceMessageToggle'],
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useChatStore, import.meta.hot))
}
