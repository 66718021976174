<script setup>
import facebook from '@/assets/icons/facebook.svg'
import instagram from '@/assets/icons/instagram.svg'
import x from '@/assets/icons/x.svg'
import visa from '@/assets/icons/visa.svg'
import mastercard from '@/assets/icons/mastercard.svg'
import visaVerified from '@/assets/icons/visa-verified.svg'
import mastercardSecure from '@/assets/icons/mastercard-secure.svg'
import logo from '@/assets/logo.svg'

const brandName = import.meta.env.VITE_BRAND_NAME
const supportEmail = import.meta.env.VITE_SUPPORT_EMAIL
const twitterHandle = import.meta.env.VITE_TWITTER_HANDLE
const instagramHandle = import.meta.env.VITE_INSTAGRAM_HANDLE
const facebookHandle = import.meta.env.VITE_FACEBOOK_HANDLE
</script>

<template>
  <div class="flex flex-col lg:gap-[30px] mt-[100px] lg:mt-[140px] pb-[80px]">
    <div
      class="flex flex-col gap-[25px] lg:gap-0 lg:flex-row justify-between items-center mb-[50px] lg:mb-0"
    >
      <img class="w-[190px] h-auto" :src="logo" alt="Logo" />
      <div class="flex gap-4 justify-center md:justify-start">
        <a
          target="_blank"
          rel="noopener"
          :href="`https://www.facebook.com/${facebookHandle}`"
        >
          <div class="bg-[#131331] p-[15px] px-[19px] rounded-full">
            <img :src="facebook" alt="Facebook logo" />
          </div>
        </a>
        <a
          target="_blank"
          rel="noopener"
          :href="`https://instagram.com/${instagramHandle}`"
        >
          <div class="bg-[#131331] p-[15px] rounded-full">
            <img :src="instagram" alt="Instagram logo" />
          </div>
        </a>
        <a
          target="_blank"
          rel="noopener"
          :href="`https://twitter.com/${twitterHandle}`"
        >
          <div class="bg-[#131331] p-[15px] rounded-full">
            <img :src="x" alt="X logo" />
          </div>
        </a>
      </div>
    </div>
    <div
      class="flex flex-col lg:flex-row items-center justify-between w-full md:text-left border border-white border-opacity-5 rounded-[15px] py-[35px] px-[30px]"
      style="
        background: radial-gradient(
          123.96% 123.96% at 18.89% 102.14%,
          rgba(76, 12, 81, 0.2) 0%,
          rgba(37, 6, 38, 0) 100%
        );
      "
    >
      <div
        class="text-[14px] text-center lg:text-left lg:flex-1 2xl:flex-[unset]"
      >
        <p class="mb-[23px]">
          Copyright © 2024, <span>{{ brandName }}.</span>
        </p>
        <p>
          All content in this website is
          <span class="text-[#CC47FF]">AI-generated.</span>
        </p>
      </div>
      <div class="text-[14px]">
        <div
          class="flex flex-col items-center lg:flex-row gap-[22px] lg:gap-[31px] mb-[38px] mt-[41px] lg:mt-0 lg:mb-[23px]"
        >
          <router-link to="/terms-of-service">Terms of Service</router-link>
          <router-link to="/privacy-policy">Privacy Policy</router-link>
          <router-link to="/cancellation-policy"
            >Cancellation Policy</router-link
          >
        </div>
        <div class="text-right">
          <span>{{ supportEmail }}</span>
        </div>
      </div>
    </div>
    <div class="flex flex-col lg:flex-row justify-between items-center">
      <div
        class="h-8 flex gap-4 my-[25px] lg:my-0 justify-center md:justify-start"
      >
        <div
          class="flex justify-center items-center w-[54px] h-[35px] bg-[#0C0D1F] rounded-[6px]"
        >
          <img
            class="w-[33px] h-auto"
            :src="visa"
            alt="Visa Logo"
            loading="lazy"
          />
        </div>
        <div
          class="flex justify-center items-center w-[54px] h-[35px] bg-[#0C0D1F] rounded-[6px]"
        >
          <img :src="mastercard" alt="MasterCard Logo" loading="lazy" />
        </div>
        <div
          class="flex justify-center items-center w-[54px] h-[35px] bg-[#0C0D1F] rounded-[6px]"
        >
          <img
            :src="visaVerified"
            class="w-[40px]"
            alt="Verified By Visa Logo"
            loading="lazy"
          />
        </div>
        <div
          class="flex justify-center items-center w-[54px] h-[35px] bg-[#0C0D1F] rounded-[6px]"
        >
          <img
            class="w-[40px]"
            :src="mastercardSecure"
            alt="MasterCard SecureCode Logo"
            loading="lazy"
          />
        </div>
      </div>
      <div class="flex lg:block gap-[26px] lg:gap-0 mb-[30px] lg:mb-0">
        <router-link class="block lg:hidden text-[14px]" to="/affiliate"
          >Become an Affiliate</router-link
        >
      </div>
    </div>
  </div>
</template>

<style scoped></style>
