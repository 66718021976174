export default function getRecaptchaExpectedAction(path) {
  if (path.startsWith('/user/payment/')) {
    return 'purchase'
  }
  if (path.startsWith('/model/create')) {
    return 'create_model'
  }
  if (path.startsWith('/model/regenerate')) {
    return 'regenerate_model'
  }
  if (path.startsWith('/model/')) {
    return 'view_model'
  }
  if (path.startsWith('/auth/signup')) {
    return 'signup'
  }

  throw new Error(`Cannot find expected action for path ${path}`)
}
