export default async function safeGenerateRecaptchaToken(action) {
  await new Promise((resolve) => {
    if (
      window.grecaptcha &&
      window.grecaptcha.enterprise &&
      window.grecaptcha.enterprise.execute
    ) {
      resolve()
    } else {
      const checkInterval = setInterval(() => {
        if (
          window.grecaptcha &&
          window.grecaptcha.enterprise &&
          window.grecaptcha.enterprise.execute
        ) {
          clearInterval(checkInterval)
          resolve()
        }
      }, 1000)
    }
  })

  await new Promise((resolve) => {
    grecaptcha.enterprise.ready(() => resolve())
  })

  const token = await grecaptcha.enterprise.execute(
    import.meta.env.VITE_RECAPTCHA_KEY,
    {
      action,
    },
  )

  console.log('reCAPTCHA Token:', token) // Log the generated token

  return token
}
