<script setup>
import cdnFile from '@/helpers/cdnFile.js'
import { watch, toRefs } from 'vue'
import { useMixpanel } from '@/composables/mixpanel'
import Popup from './Popup.vue'
import ButtonComponent from './ButtonComponent.vue'
import logo from '@/assets/logo.svg'

const props = defineProps({
  open: Boolean,
})
const { open } = toRefs(props)

const emit = defineEmits(['update:open'])

const mixpanel = useMixpanel()

watch(open, (val) => {
  if (val) {
    mixpanel.track('open_login_register_popup', {}, { source: 'current' })
  }
})
</script>

<template>
  <Popup
    class="sticky"
    :open="open"
    :mobile="false"
    popupStyle="bg-[#0A0D22] w-[625px] pt-[50px] pb-5 px-4 lg:py-[74px] mx-4 lg:mx-0"
    @update:open="emit('update:open', $event)"
    style="z-index: 999999"
  >
    <div>
      <div class="flex flex-col items-center gap-[25px] text-center">
        <img class="w-[148px] h-auto" :src="logo" alt="Logo" />
        <h2 class="text-[30px] lg:text-[35px] leading-[1]">
          Create a Free Account
        </h2>
        <p for="gender" class="text-[#B1B5DB]">
          This feature is only available to registered users
        </p>
      </div>
      <div
        class="flex flex-col items-center mt-6 m-[1px] lg:mt-[34px] gap-x-[18px] gap-4"
      >
        <ButtonComponent
          to="/register"
          class="flex justify-center items-center py-[14px] max-w-[360px] font-semibold w-full"
          @click="$emit('update:open', false)"
          >Register</ButtonComponent
        >
        <!-- <div class="text-sm text-[#B1B5DB]">
          Already have an account?
          <router-link to="/login" @click="$emit('update:open', false)"
            >Login</router-link
          >
        </div> -->
      </div>
    </div>
  </Popup>
</template>
