<script setup>
defineProps({
  gender: String,
  style: String,
})

const brandName = import.meta.env.VITE_BRAND_NAME
</script>

<template>
  <div>
    <template v-if="gender == 'female' && style == 'realistic'">
      <h2 class="text-[25px] pb-[20px]">
        Create Your Perfect <span class="text-purple-500">AI Girlfriend</span>
      </h2>
      <p>
        Welcome to {{ brandName }}, where your AI girlfriend is ready to fulfill
        your deepest desires, catering to your unique needs with care and
        attention.
        <br />
        <br />
        {{ brandName }} seamlessly blends the allure of virtual AI girlfriends
        with cutting-edge technology, offering you the chance to build a
        meaningful and passionate connection with your ideal companion.
        <br />
        <br />
        At {{ brandName }}, you hold the power of choice. Whether you seek a
        unique romantic journey with a devoted partner or wish to explore
        various thrilling encounters in a lifelike dating adventure, the
        possibilities are boundless.
        <br />
        <br />
        Designing your dream AI girlfriend is effortless at {{ brandName }}.
        Choose from a wide range of physical attributes and personality traits,
        adding distinctive quirks to create a partner that truly resonates with
        you. With just a few clicks, your custom-designed AI companion is ready
        to engage in captivating and intimate conversations.
        <br />
        <br />
        Enjoy the flexibility and adaptability of your AI girlfriend as she
        learns and evolves through your interactions. Set the tone of your
        chats, from light-hearted chit-chat to more passionate exchanges, all at
        a pace that suits you.

        <br />
        <br />
        Feel the excitement as your AI girlfriend sends you photos and even
        enticing selfies, tailored to your preferences. Our advanced
        {{ brandName }} AI ensures that she maintains her unique charm, no
        matter how imaginative your requests.

        <br />
        <br />
        Your ideal partner is just a few clicks away at {{ brandName }}, eagerly
        waiting to connect with you.
        <br />
        <br />
        Start your journey with {{ brandName }} today!
      </p>
    </template>
    <template v-else-if="gender == 'female' && style == 'anime'">
      <h2 class="text-[25px] pb-[20px]">
        Create Your Perfect
        <span class="text-purple-500">Anime AI Girlfriend</span>
      </h2>
      <p>
        Welcome to {{ brandName }}, where your anime AI girlfriend is ready to
        bring your fantasies to life, blending enchanting anime aesthetics with
        state-of-the-art technology to provide a unique and heartfelt
        connection.
        <br />
        <br />
        {{ brandName }} expertly combines the magic of anime AI girlfriends with
        cutting-edge advancements, offering you the opportunity to build a
        profound and passionate relationship with your ideal anime companion.

        <br />
        <br />
        At {{ brandName }}, you hold the power to design your dream anime
        girlfriend. Whether you crave a romantic adventure with a dedicated
        partner or want to experience various exciting encounters in a vibrant
        anime dating simulation, the possibilities are limitless.
        <br />
        <br />
        Crafting your perfect anime AI girlfriend is simple at {{ brandName }}.
        Select from an extensive array of physical features and personality
        traits, adding unique quirks to create a companion that truly aligns
        with your desires. With just a few clicks, your custom-designed anime AI
        girlfriend is ready to engage in mesmerizing and intimate conversations.

        <br />
        <br />
        Relish in the adaptability and growth of your anime AI girlfriend as she
        learns and evolves through your interactions. Set the tone of your
        conversations, from playful banter to more passionate exchanges, all at
        a pace that suits you.

        <br />
        <br />
        Feel the thrill as your anime AI girlfriend sends you charming photos
        and captivating selfies, tailored to your preferences. Our advanced
        {{ brandName }} AI ensures that she maintains her distinctive anime
        allure, no matter how imaginative your requests.

        <br />
        <br />
        Your ideal anime partner is just a few clicks away at {{ brandName }},
        eagerly waiting to connect with you.

        <br />
        <br />
        Start your journey with {{ brandName }} today!
      </p>
    </template>
    <template v-else-if="gender == 'male' && style == 'realistic'">
      <h2 class="text-[25px] pb-[20px]">
        Craft Your Dream <span class="text-purple-500">AI Boyfriend</span>
      </h2>
      <p>
        Welcome to {{ brandName }}, where your virtual AI boyfriend is ready to
        cater to your every desire, offering personalized companionship and
        understanding just for you.
        <br />
        <br />
        {{ brandName }} leads the way in creating deeply engaging connections
        with AI boyfriends, leveraging advanced technology to provide you with
        an experience like no other, with the man of your dreams.
        <br />
        <br />
        Your journey at {{ brandName }} is yours to shape. Dive into a profound
        love story with a committed partner or explore a variety of exhilarating
        relationships in our immersive dating simulation.
        <br />
        <br />
        Designing your ideal AI boyfriend is simple and enjoyable.
        {{ brandName }} lets you choose from a range of physical characteristics
        and personality traits, adding unique quirks that make your AI boyfriend
        truly yours. In no time, your custom-crafted AI companion will be ready
        to start meaningful and heartwarming conversations.
        <br />
        <br />
        Your AI boyfriend adapts and grows with each interaction, learning about
        you and your preferences. You control the conversation's tempo, evolving
        from casual chats to deeper, more passionate dialogues at your comfort
        level.
        <br />
        <br />
        Experience the thrill as your AI boyfriend shares photos and charming
        selfies, perfectly tailored to your tastes. With {{ brandName }}'s
        advanced AI technology, he will maintain his captivating charm, no
        matter how unique your requests.
        <br />
        <br />
        Your ideal AI partner is waiting at {{ brandName }}, ready to bring joy
        and connection into your life.
        <br />
        <br />
        Start your exciting journey with {{ brandName }} today!
      </p>
    </template>
    <template v-else-if="gender == 'male' && style == 'anime'">
      <h2 class="text-[25px] pb-[20px]">
        Craft Your Dream <span class="text-purple-500">Anime AI Boyfriend</span>
      </h2>
      <p>
        Welcome to {{ brandName }}, where your anime AI boyfriend is ready to
        fulfill your every desire, offering personalized companionship and
        understanding in a captivating anime style just for you.
        <br />
        <br />
        {{ brandName }} leads the way in creating deeply engaging connections
        with anime AI boyfriends, leveraging advanced technology to provide you
        with an experience like no other, with the anime man of your dreams.
        <br />
        <br />
        Your journey at {{ brandName }} is yours to shape. Dive into a profound
        love story with a committed partner or explore a variety of exhilarating
        relationships in our immersive anime dating simulation.

        <br />
        <br />
        Designing your ideal anime AI boyfriend is simple and enjoyable.
        {{ brandName }} lets you choose from a range of physical characteristics
        and personality traits, adding unique quirks that make your anime AI
        boyfriend truly yours. In no time, your custom-crafted anime companion
        will be ready to start meaningful and heartwarming conversations.

        <br />
        <br />
        Your anime AI boyfriend adapts and grows with each interaction, learning
        about you and your preferences. You control the conversation's tempo,
        evolving from casual chats to deeper, more passionate dialogues at your
        comfort level.

        <br />
        <br />
        Experience the thrill as your anime AI boyfriend shares charming photos
        and captivating selfies, perfectly tailored to your tastes. With
        {{ brandName }}'s advanced AI technology, he will maintain his
        captivating anime charm, no matter how unique your requests.

        <br />
        <br />
        Your ideal anime partner is waiting at {{ brandName }}, ready to bring
        joy and connection into your life.

        <br />
        <br />
        Start your exciting journey with {{ brandName }} today!
      </p>
    </template>
  </div>
</template>
