export default [
  {
    name: 'Action',
    tags: [
      'Standing',
      'Sitting',
      'Squatting',
      'Lying Down',
      'Working Out',
      'Swimming',
    ],
  },
  {
    name: 'View',
    tags: [
      'Rear Shot',
      'Front Shot',
      'Full Body Shot',
      'Half Body Shot',
      'Close Up Shot',
    ],
  },
  {
    name: 'Clothing',
    tags: [
      'Summer Dress',
      'Bikini',
      'Skirt',
      'Jeans',
      'Lab Coat',
      'Teacher',
      'Pyjamas',
      'Tank Top',
      'Sport Outfit',
    ],
  },
  {
    name: 'Scene',
    tags: ['Garden', 'Gym', 'Forest', 'Beach', 'Taxi'],
  },
  {
    name: 'Accessories',
    tags: ['Necklace', 'Glasses', 'Candles', 'Scarf', 'Baseball Cap'],
  },
]
