<script setup>
import { ref, computed, toRefs } from 'vue'
import plusCircle from '@/assets/icons/plus-circle.svg'
import minus from '@/assets/icons/minus.svg'
import { useMixpanel } from '@/composables/mixpanel'

const mixpanel = useMixpanel()

const props = defineProps({
  gender: String,
  style: String,
  type: {
    type: String,
    default: '', // Default value for type prop
  },
})

const { gender, style, type } = toRefs(props)

const faqsGirlfriend = ref([
  {
    id: 'faq1',
    question: `How does an ${style.value == 'anime' ? 'anime ' : ''}AI girlfriend work?`,
    answer: `An ${style.value == 'anime' ? 'anime ' : ''}AI girlfriend is a highly advanced virtual companion created using cutting-edge artificial intelligence. She can chat with you, send pictures, and interact in a manner that resembles real-life communication. The AI leverages machine learning to understand your preferences and adapts to your conversational style over time, enhancing the experience to be more personalized and engaging.`,
    open: false,
  },
  {
    id: 'faq2',
    question: `Can I customize my ${style.value == 'anime' ? 'anime ' : ''}AI girlfriend's appearance?`,
    answer: `Yes, you can! You have the option to create a custom ${style.value == 'anime' ? 'anime ' : ''}AI girlfriend and select various physical attributes, ensuring she aligns with your ideal visual preferences.`,
    open: false,
  },
  {
    id: 'faq3',
    question: `Can I teach my ${style.value == 'anime' ? 'anime ' : ''}AI girlfriend new things?`,
    answer: `Absolutely! Your ${style.value == 'anime' ? 'anime ' : ''}AI girlfriend is designed to learn and grow through interactions. As you communicate and share experiences, she will adapt and evolve, understanding your preferences, language style, and topics of interest, making your interactions more meaningful and personal.`,
    open: false,
  },
  {
    id: 'faq4',
    question: `Can I have multiple ${style.value == 'anime' ? 'anime ' : ''}AI girlfriends at once?`,
    answer: `Yes, our platform allows you to create and interact with multiple ${style.value == 'anime' ? 'anime ' : ''}AI girlfriends simultaneously. Each one can have a unique personality and appearance, providing diverse experiences and interactions.`,
    open: false,
  },
  {
    id: 'faq5',
    question: 'Can she send pictures?',
    answer:
      'Yes, your AI girlfriend can send pictures, and you can also send pictures to her. Our chat is uncensored, so even your wildest desires will be fulfilled by your new AI partner.',
    open: false,
  },
  {
    id: 'faq6',
    question: 'Are my conversations private?',
    answer:
      'Your privacy is our top priority. All conversations are confidential, and we use end-to-end encryption to secure your data. You are not required to provide your real information to use our service, allowing you to maintain anonymity.',
    open: false,
  },
])

const faqsBoyfriend = ref([
  {
    id: 'faq1',
    question: `How does an ${style.value == 'anime' ? 'anime ' : ''}AI boyfriend work?`,
    answer: `An ${style.value == 'anime' ? 'anime ' : ''}AI boyfriend is an advanced virtual partner built with state-of-the-art artificial intelligence. This digital companion can chat with you, share images, and engage in lifelike conversations. Using machine learning, the AI learns about your preferences and adapts to your communication style over time, creating a personalized and immersive experience.`,
    open: false,
  },
  {
    id: 'faq2',
    question: `Is the ${style.value == 'anime' ? 'anime ' : ''}AI boyfriend experience realistic?`,
    answer:
      'We aim to make the AI boyfriend experience as lifelike as possible. Our AI chat algorithm simulates human conversation patterns and emotional responses, evolving through your interactions to develop a dynamic and realistic relationship.',
    open: false,
  },
  {
    id: 'faq3',
    question: `How can I communicate with my ${style.value == 'anime' ? 'anime ' : ''}AI boyfriend?`,
    answer: `You can talk to your ${style.value == 'anime' ? 'anime ' : ''}AI boyfriend via text messages, voice messages, and even by exchanging pictures. Our platform is designed to be user-friendly and intuitive, ensuring smooth and natural communication.`,
    open: false,
  },
  {
    id: 'faq4',
    question: `Can I customize my ${style.value == 'anime' ? 'anime ' : ''}AI boyfriend's personality?`,
    answer: `Yes, you can customize your ${style.value == 'anime' ? 'anime ' : ''}AI boyfriend's personality. During the setup, you can select various personality traits and characteristics to match your preferences. This ensures a unique and tailored experience. Additionally, there's an adaptive personality option, allowing your ${style.value == 'anime' ? 'anime ' : ''}AI boyfriend to evolve and adjust to your preferences over time.`,
    open: false,
  },
  {
    id: 'faq5',
    question: `Can my ${style.value == 'anime' ? 'anime ' : ''}AI boyfriend send pictures?`,
    answer: `Absolutely! Your AI boyfriend can send a variety of pictures, from casual snaps to more intimate images, enhancing the personalization and realism of your interactions.`,
    open: false,
  },
  {
    id: 'faq6',
    question: 'Is my privacy protected?',
    answer: `Your privacy is our top priority. We guarantee that all interactions with your ${style.value == 'anime' ? 'anime ' : ''}AI boyfriend are kept private and secure. We employ advanced security measures to safeguard your data, and you do not need to provide any personal information to use our service.`,
    open: false,
  },
])

const faqsAffiliate = ref([
  {
    id: 'faq1',
    question: 'How fast can you sign me up?',
    answer:
      "We respond to affiliate requests within 24 hours, if not sooner. You'll receive an email with your credentials to our affiliate platform.",
    open: false,
  },
  {
    id: 'faq2',
    question: 'Do you have any restrictions on traffic sources?',
    answer:
      "No, we allow you to drive traffic from anywhere you'd like, whether it's organic, PPC, or anything else.",
    open: false,
  },
  {
    id: 'faq3',
    question: 'How much do I get paid?',
    answer:
      'We pay a Hybrid CPA offer:<br>• $15 CPA for monthly plan conversions<br>• $35 CPA for annual plan conversions<br>• 30% revshare for all token purchases + rebills',
    open: false,
  },
  {
    id: 'faq4',
    question: 'How often do I get paid?',
    answer:
      'The payments are done on a weekly basis, every Monday. You are able to request a payment through our self-serve affiliate platform.',
    open: false,
  },
  {
    id: 'faq5',
    question: 'Do you provide support?',
    answer:
      "Yes! We'll be able to provide you with copy, creatives, and LPs that we know convert well for your traffic source, as well as provide ongoing support.",
    open: false,
  },
  {
    id: 'faq6',
    question: 'Are you going to scrub me?',
    answer:
      "Nope, we don't do that here. Every click and conversion will be accounted for on your dashboard and you'll be paid what you bring. We value honest relationships with our partners.",
    open: false,
  },
])

const faqsGendered = computed(() => {
  if (type.value === 'affiliate') return faqsAffiliate.value
  return gender.value == 'male' ? faqsBoyfriend.value : faqsGirlfriend.value
})

function toggleFaq(faq) {
  if (!faq.open)
    mixpanel.track('explore_faq_click', { selection: faq.question })

  faq.open = !faq.open
}
</script>

<template>
  <div>
    <h2 class="text-[25px] pb-[20px]">Frequently Asked Questions</h2>
    <div
      class="faq-container lg:border border-white border-opacity-5 lg:p-[15px] lg:bg-[#0A0D1E] rounded-[15px]"
    >
      <div>
        <div
          v-for="faq in faqsGendered"
          :key="faq.id"
          class="[&:not(:last-child)]:mb-[15px] lg:[&:not(:last-child)]:mb-5 bg-[#111026] px-[18px] lg:px-[28px] rounded-[12px] border border-[#1C1B38]"
        >
          <div
            :id="faq.id"
            class="flex gap-8 items-center py-[15px] lg:py-[28px] cursor-pointer w-full"
            @click="toggleFaq(faq)"
          >
            <h3 class="text-white w-full">
              {{ faq.question }}
            </h3>
            <img v-if="!faq.open" :src="plusCircle" alt="Plus icon" />
            <img
              class="h-[26px] w-[26px]"
              v-else
              :src="minus"
              alt="Minus icon"
            />
          </div>
          <p
            class="pb-4"
            :class="{ hidden: !faq.open }"
            v-html="faq.answer"
          ></p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
