<script setup>
import { ref, computed, onMounted, nextTick } from 'vue'
import cdnFile from '@/helpers/cdnFile'
import RegisterModal from '@/components/RegisterModal.vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useHead } from '@unhead/vue'
import authParticles from '@/assets/img/auth-particles.svg'
import { useMixpanel } from '@/composables/mixpanel'
import logo from '@/assets/logo.svg'

useHead({
  title: `${import.meta.env.VITE_BRAND_NAME} | Register`,
  meta: [
    {
      name: 'description',
      content:
        'Create your account to unlock access to our app. Sign up quickly and easily to start exploring our services',
    },
  ],
})

const mixpanel = useMixpanel()

mixpanel.track('register_page_visit', {}, { source: 'previous' })

const $router = useRouter()
const $store = useStore()

const user = computed(() => $store.state.user)
const discountPopupOpen = computed(() => $store.state.discountPopupOpen)
function onRegistered() {
  if (user.value.models.length) {
    $router.push(
      $store.state.lastAppPage
        ? `/${decodeURIComponent($store.state.lastAppPage)}`
        : '/chat',
    )
  } else {
    $router.push(
      $store.state.lastAppPage
        ? `/${decodeURIComponent($store.state.lastAppPage)}`
        : '/',
    )
  }
}

onMounted(() => {
  nextTick(() => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  })
})
const premadeModel = computed(() => $store.state.premadeModel)

const validationOpen = ref(false)
</script>

<template>
  <div class="flex min-h-full flex-1 relative">
    <img class="absolute bottom-0 left-0 w-full" :src="authParticles" alt="" />
    <div
      class="flex flex-1 flex-col justify-center px-2 py-3 lg:py-12 sm:px-6 lg:px-20 xl:px-24"
    >
      <div
        class="relative z-20 bg-[#0A0D22] pt-5 lg:pt-[63px] pb-[18px] lg:pb-[45px] lg:mx-auto rounded-[35px] border border-[#141A3D] border-opacity-70 px-4 lg:px-[75px] lg:w-[625px]"
        :class="discountPopupOpen ? 'mt-[115px] lg:mt-[64px]' : ''"
      >
        <div class="flex flex-col items-center gap-[18px] lg:gap-[30px]">
          <router-link to="/">
            <img class="w-[148px] h-auto" :src="logo" alt="Logo" />
          </router-link>
          <template v-if="!validationOpen">
            <h2 class="lg:text-[35px] text-[26px] text-center leading-[1]">
              Create a Free Account
            </h2>
            <p class="text-sm lg:text-base text-[#f4f7fb] leading-[1]">
              Already a member?
              {{ ' ' }}
              <router-link to="/login" class="text-[#CC47FF]"
                >Log in</router-link
              >
            </p>
          </template>
          <template v-else>
            <h2
              class="my-[25px] text-[35px] lg:text-[38px] leading-9 tracking-tight text-white"
            >
              Verify your Email
            </h2>
          </template>
        </div>

        <RegisterModal
          :with-accept-policies="true"
          :model-ids="premadeModel ? [premadeModel.id] : []"
          :redirectPath="decodeURIComponent($store.state.lastAppPage)"
          @registered="onRegistered"
          @validationOpen="validationOpen = true"
        />
      </div>
    </div>
  </div>
</template>
