<script setup>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import queryString from 'query-string'
import logo from '@/assets/logo.svg'
import particles from '@/assets/img/auth-particles.svg'
import Confirm from '@/components/Confirm.vue'
import { useHead } from '@unhead/vue'

const $store = useStore()
const $router = useRouter()
const $route = useRoute()

const query = $route.query

useHead({
  title: `${import.meta.env.VITE_BRAND_NAME} | Purchase Complete`,
  meta: [{ name: 'robots', content: 'noindex' }],
})

onMounted(() => {
  $store.commit('SET_DISCOUNT_CODE', {})
  $store.commit('SET_DISCOUNT_POPUP', {
    open: false,
  })
})

const handleButtonClick = () => {
  if (query.type === 'purchase') {
    $store.commit('SET_BUY_LUNA_PURCHASE_NUMBER', null)
  }

  let redirectionUrl = $store.state.lastAppPage
  const redirectQuery = queryString.parse(
    decodeURIComponent(redirectionUrl).split('?')[1],
  )

  if (redirectQuery.subTab === 'method') {
    redirectionUrl = redirectionUrl.replace(
      encodeURIComponent('&subTab=method'),
      '',
    )
  }

  if (query.subscriptionType === 'upgrade') {
    redirectionUrl = redirectionUrl.replace(
      encodeURIComponent('&subTab=subscription'),
      '',
    )
  }

  $router.replace(
    redirectionUrl && query.type !== 'purchase'
      ? `/${decodeURIComponent(redirectionUrl)}`
      : decodeURIComponent($store.state.purchaseRedirectPath),
  )
}
</script>

<template>
  <div class="w-full h-full">
    <img class="absolute bottom-0 left-0 w-full" :src="particles" />
    <Confirm
      :open="true"
      :closeButton="false"
      popupStyle="bg-[#0A0D22] lg:w-[625px] pt-[50px] pb-5 px-4 lg:py-[75px] mx-4 lg:mx-0 lg:px-[122px]"
      type=""
      @confirm="handleButtonClick"
      :buttonText="{ confirm: 'Continue' }"
    >
      <div class="flex flex-col items-center gap-[25px]">
        <img class="w-[148px] h-auto" :src="logo" />
        <p class="text-[30px] lg:text-[35px] text-center font-bold">
          {{
            query.type === 'subscription'
              ? query.subscriptionType === 'initial'
                ? 'Welcome to Pro'
                : 'Upgraded'
              : query.type === 'purchase'
                ? 'Thank you for your purchase'
                : 'Card changed'
          }}
        </p>
        <p class="text-center">
          {{
            query.type === 'subscription'
              ? query.subscriptionType === 'initial'
                ? 'Your order was successfully processed and your account has been upgraded to pro!'
                : 'Your order was successfully processed and your account has been upgraded to the annual plan!'
              : query.type === 'purchase'
                ? `Your ${query.luna} tokens have been credited to your account`
                : 'Your new card has been successfully added to your account'
          }}
        </p>
      </div>
    </Confirm>
  </div>
</template>
