import { defineStore, acceptHMRUpdate } from 'pinia'

export const useCreateModelStore = defineStore('createModel', {
  state: () => ({
    pendingModel: null,
  }),
  actions: {
    setPendingModel(newPendingModel) {
      this.pendingModel = newPendingModel
    },
    resetPendingModel() {
      this.pendingModel = null
    },
  },
  persist: {
    storage: sessionStorage,
    paths: ['pendingModel'],
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCreateModelStore, import.meta.hot))
}
