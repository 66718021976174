const TIMEOUT_ERROR = 'Image Generation timed out'

function isServerOverloaded(error) {
  const message = error?.response?.data?.message || error.message || ''
  return (
    error.code === 'ETIMEDOUT' ||
    error.code === 'ECONNABORTED' ||
    message.includes('Server overload') ||
    message.includes('timed out') ||
    message.includes(TIMEOUT_ERROR)
  )
}

export default isServerOverloaded
