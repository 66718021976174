import { defineStore, acceptHMRUpdate } from 'pinia'

export const usePaymentError = defineStore('paymentError', {
  state: () => ({
    paymentError: null,
  }),
  actions: {
    setPaymentError(error) {
      this.paymentError = error
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePaymentError, import.meta.hot))
}
