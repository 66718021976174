import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'
import * as Sentry from '@sentry/vue'
import mixpanel from 'mixpanel-browser'
import createMixpanelData from 'common/lib/createMixpanelData'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    analytics: state.analytics,
    utm: state.utm,
    userBearerToken: state.userBearerToken,
    discountCode: state.discountCode,
    discountEndDate: state.discountEndDate,
    lastAppPage: state.lastAppPage,
  }),
})

// Create a new store instance.
const store = createStore({
  plugins: [vuexLocal.plugin],
  state() {
    return {
      user: null,
      userBearerToken: null,
      premadeModel: null,
      analytics: {},
      utm: {},
      selectedModel: null,
      hasMore: true,
      messages: [],
      typing: false,
      noBalancePopupOpen: false,
      subscribePopupOpen: false,
      subscribePopupText: '',
      subscribeRedirectPath: '/',
      genericPopupOpen: false,
      genericPopupTitle: '',
      genericPopupContent: '',
      purchaseRedirectPath: '/',
      discountPopupOpen: false,
      discountCode: null,
      discountEndDate: null,
      buyLunaPopupOpen: false,
      buyLunaPurchaseNumber: null,
      loginRegisterPopupOpen: false,
      mobileMenuOpen: false,
      lastAppPage: null,
      routes: {
        previous: null,
        current: null,
      },
      chatSessionId: null,
    }
  },
  mutations: {
    SET_CHAT_SESSION_ID(state, id) {
      state.chatSessionId = id
    },
    SET_ROUTES(state, { previous, current }) {
      state.routes.previous = previous.path.slice(1)
      state.routes.current = current.path.slice(1)
    },
    SET_PURCHASE_REDIRECT_PATH(state, path) {
      state.purchaseRedirectPath = path
    },
    SET_USER_BEARER_TOKEN(state, token) {
      state.userBearerToken = token
    },
    SET_USER(state, user) {
      state.user = user
    },
    SET_SELECTED_MODEL(state, selectedModel) {
      state.selectedModel = selectedModel
    },
    SET_PREMADE_MODEL(state, model) {
      state.premadeModel = model
    },
    MAKE_MODEL_FIRST(state, { modelId, message }) {
      const modelIndex = state.user.models.findIndex((m) => m.id == modelId)
      state.user.models[modelIndex].messages = [message]
      if (modelIndex == 0) return
      const model = state.user.models[modelIndex]
      state.user.models.splice(modelIndex, 1)
      state.user.models.unshift(model)
    },
    ADD_MESSAGES(state, messages) {
      state.messages.push(...messages)
      if (typeof state.user.totalMessageCount === 'number')
        state.user.totalMessageCount += messages.length
    },
    SET_MESSAGES(state, messages) {
      state.messages = messages
    },
    ADD_OLDER_MESSAGES(state, messages) {
      state.messages.unshift(...messages)
    },
    DELETE_RECENT_MESSAGES(state, count) {
      for (let i = 0; i < count; i++) state.messages.pop()
    },
    SET_HAS_MORE(state, val) {
      state.hasMore = val
    },
    REPLACE_MESSAGE(state, message) {
      const index = state.messages.findIndex((m) => m.id == message.id)
      if (index == -1)
        throw new Error(`Message with id ${message.id} not found on storage`)
      state.messages[index] = message
    },
    REPLACE_MODEL(state, { oldModelId, newModel }) {
      state.user.models[
        state.user.models.findIndex((model) => model.id == oldModelId)
      ] = newModel
      if (state.selectedModel == oldModelId) state.selectedModel = newModel.id
    },
    CHANGE_LUNA(state, val) {
      // Users on trial always have 0 luna
      if (state.user.subscription) {
        state.user.luna += val
      }
    },
    ADD_MODEL(state, val) {
      state.user.models.unshift(val)
      if (
        typeof state.user.totalCustomModelCount === 'number' &&
        !val.premadeId
      )
        state.user.totalCustomModelCount++
    },
    DELETE_MODEL(state, modelId) {
      const idx = state.user.models.findIndex((m) => m.id == modelId)
      state.user.models.splice(idx, 1)
    },
    SET_ANALYTICS_VARIABLE(state, { key, val }) {
      state.analytics[key] = val
    },
    SET_DISCOUNT_CODE(state, { code, endDate }) {
      state.discountCode = code
      state.discountEndDate = endDate
    },
    SET_DISCOUNT_POPUP(state, { open }) {
      state.discountPopupOpen = open
    },
    SET_SUBSCRIPTION_CANCELLED(state, val) {
      state.user.subscriptionCancelled = val
    },
    SET_SUBSCRIPTION(state, val) {
      state.user.subscription = val
    },
    SET_SUBSCRIPTION_NEXT_PAYMENT_DATE(state, val) {
      state.user.subscriptionNextPaymentDate = val
    },
    SET_NAME(state, val) {
      state.user.name = val
    },
    SET_UTM(state, val) {
      state.utm = val
    },
    CLEAR_UTM(state, val) {
      state.utm = []
    },
    SAVE_MESSAGE(state, { id, saved }) {
      state.messages.find((m) => m.id == id).saved = saved
    },
    ADD_USER_IMAGES(state, images) {
      state.user.images.push(...images)
    },
    SET_TYPING(state, val) {
      console.log('setting typing', val)
      state.typing = val
    },
    SET_SUBSCRIBE_POPUP(state, { open, text }) {
      state.subscribePopupOpen = open
      if (open && text) {
        state.subscribePopupText = text
      } else if (!open) {
        // The delay exists because there is a transition to closing the popup
        // we want the text to be changed only after the popup is closed
        // so it doesn't flash
        setTimeout(
          () => !state.subscribePopupOpen && (state.subscribePopupText = ''),
          500,
        )
      }
    },
    SET_NO_BALANCE_POPUP(state, { open }) {
      state.noBalancePopupOpen = open
    },
    // generic popup for errors and similar information relevant to user
    SET_GENERIC_POPUP(state, { open, title, content }) {
      state.genericPopupOpen = open
      state.genericPopupTitle = title
      state.genericPopupContent = content
    },
    SET_BUY_LUNA_POPUP(state, { open, purchaseNumber }) {
      state.buyLunaPopupOpen = open
      state.buyLunaPurchaseNumber = purchaseNumber
    },
    SET_LOGIN_REGISTER_POPUP_OPEN(state, { open }) {
      state.loginRegisterPopupOpen = open
    },
    SET_MOBILE_MENU_OPEN(state, { open }) {
      state.mobileMenuOpen = open
    },
    UPDATE_LAST_APP_PAGE(state) {
      let lastAppPage =
        window.location.pathname.slice(1) + window.location.search

      if (lastAppPage.includes('?discountCode=')) {
        lastAppPage = lastAppPage.split('?discountCode=')[0]
      }

      state.lastAppPage = encodeURIComponent(lastAppPage)
    },
    SET_BUY_LUNA_PURCHASE_NUMBER(state, purchaseNumber) {
      state.buyLunaPurchaseNumber = purchaseNumber
    },
  },
  actions: {
    setUser({ state, commit }, user) {
      commit('SET_USER', user)

      if (user) {
        if (user.models?.length) {
          commit('SET_SELECTED_MODEL', user.models[0].id)
        }

        if (import.meta.env.VITE_TIKTOK_PIXEL_CODE) {
          ttq.identify({
            email: user.email,
          })
        }

        if (window.hj) {
          hj('identify', user.id)
        }

        if (import.meta.env.VITE_MIXPANEL_TOKEN) {
          mixpanel.identify(user.id)
        }

        if (
          import.meta.env.VITE_SENTRY_DSN &&
          import.meta.env.VITE_NODE_ENV == 'production'
        ) {
          Sentry.setUser({ email: user.email })
        }
      } else {
        if (import.meta.env.VITE_MIXPANEL_TOKEN) {
          console.log('clear mixpanel')
          mixpanel.reset()
          // mixpanel.cookie.clear()
        }
      }

      if (import.meta.env.VITE_MIXPANEL_TOKEN) {
        let mixpanelData = createMixpanelData(user)

        mixpanel.register(mixpanelData) // Super property
        mixpanel.people.set(mixpanelData) // Profile property
      }
    },
    setPremadeModel({ commit }, payload) {
      commit('SET_PREMADE_MODEL', payload)
    },
    addMessages({ commit }, payload) {
      commit('ADD_MESSAGES', payload)
    },
    setMessages({ commit }, payload) {
      commit('SET_MESSAGES', payload)
    },
    addOlderMessages({ commit }, payload) {
      commit('ADD_OLDER_MESSAGES', payload)
    },
    deleteRecentMessages({ commit }, payload) {
      commit('DELETE_RECENT_MESSAGES', payload)
    },
    setHasMore({ commit }, payload) {
      commit('SET_HAS_MORE', payload)
    },
    replaceMessage({ commit }, payload) {
      commit('REPLACE_MESSAGE', payload)
    },
    changeLuna({ commit }, delta) {
      commit('CHANGE_LUNA', delta)
    },
    addModel({ commit }, payload) {
      commit('ADD_MODEL', payload)
    },
    deleteModel({ commit }, id) {
      commit('DELETE_MODEL', id)
    },
    setSubscriptionCancelled({ commit }, payload) {
      commit('SET_SUBSCRIPTION_CANCELLED', payload)
    },
    setSubscription({ commit }, payload) {
      commit('SET_SUBSCRIPTION', payload)
    },
    setSubscriptionNextPaymentDate({ commit }, payload) {
      commit('SET_SUBSCRIPTION_NEXT_PAYMENT_DATE', payload)
    },
    setName({ commit }, payload) {
      commit('SET_NAME', payload)
    },
    openSubscribePopup({ commit }, text = '') {
      commit('SET_SUBSCRIBE_POPUP', { open: true, text })
    },
    closeSubscribePopup({ commit }) {
      commit('SET_SUBSCRIBE_POPUP', { open: false })
    },
    openLoginRegisterPopup({ commit }) {
      commit('SET_LOGIN_REGISTER_POPUP_OPEN', { open: true })
    },
    closeLoginRegisterPopup({ commit }) {
      commit('SET_LOGIN_REGISTER_POPUP_OPEN', { open: false })
    },
  },
  getters: {
    model: (state) =>
      state.user
        ? state.user?.models.find((m) => m.id == state.selectedModel)
        : state.premadeModel,
    firstModel: (state) =>
      state.user?.models.length ? state.user?.models[0] : null,
    models: (state) => state.user?.models,
    deterModel: (state) => state.user?.models.find((model) => model.deterImage),
    showHints: (state) => state.user.models.length <= 1,
  },
})

export default store
