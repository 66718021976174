<script setup>
import { ref, inject, computed } from 'vue'
import { useStore } from 'vuex'
import { useLoading } from 'vue-loading-overlay'
import MaleRotated from './icons/MaleRotated.vue'
import Female from './icons/Female.vue'
import logoicon from '@/assets/logoicon.svg'
import { useMixpanel } from '@/composables/mixpanel'
import { onMounted } from 'vue'
import { useWindowSize } from '@vueuse/core'

const windowSize = useWindowSize()

const isLgWindowSize = computed(() => {
  return windowSize.width.value > 1024
})

const mixpanel = useMixpanel()

onMounted(() => {
  mixpanel.track('open_select_gender_popup')
})

const genders = [
  { id: 'male', name: 'Male', icon: MaleRotated },
  { id: 'female', name: 'Female', icon: Female },
  { id: 'other', name: 'Other', icon: logoicon },
]

const $store = useStore()

const $axios = inject('axios')

const $loading = useLoading({
  canCancel: false,
  isFullPage: true,
  color: '#a15cff',
})

const newGender = ref(null)

async function change() {
  const loader = $loading.show()

  const newUser = await $axios
    .post('/user/change', { gender: newGender.value.id })
    .then((res) => res.data)
  console.log(newUser)
  $store.dispatch('setUser', newUser)

  loader.hide()
}

const handleGenderChange = (newValue) => {
  if (newGender.value) return

  newGender.value = newValue
  change()
}
</script>

<template>
  <div
    class="fixed flex flex-col items-center pt-4 lg:pt-[234px] z-50 top-0 left-0 bottom-0 right-0 bg-[#070917]"
  >
    <p class="text-[28px] lg:text-[35px] mb-[30px]">Select your gender</p>
    <div class="flex flex-col lg:flex-row gap-5">
      <div
        @click="handleGenderChange(gender)"
        v-for="gender in genders"
        class="relative flex flex-col pt-5 lg:pt-7 gap-5 lg:gap-6 justify-center items-center bg-[#0A0D1E] w-[23vh] h-[23vh] lg:w-[280px] lg:h-[280px] cursor-pointer rounded-[25px] border border-[#111631]"
        :class="
          newGender && gender.id === newGender.id ? 'border-[#CC47FF]' : ''
        "
        :style="
          newGender && gender.id === newGender.id
            ? { boxShadow: '0px 0px 0px 5px #CC47FF' }
            : {}
        "
      >
        <img
          v-if="gender.id === 'other'"
          :src="gender.icon"
          alt="Gender icon"
          class="w-[45px] lg:w-[57px] h-auto"
        />
        <component
          v-else
          :is="gender.icon"
          color="#CC47FF"
          :size="isLgWindowSize ? 57 : 45"
        />
        <div class="text-[20px] lg:text-[25px] leading-[1] -pt-5">
          {{ gender.name }}
        </div>
      </div>
    </div>
  </div>
</template>
