<script setup>
import { ref, computed, inject } from 'vue'
import {
  ChevronLeftIcon,
  MagnifyingGlassPlusIcon,
} from '@heroicons/vue/24/outline'
import { useHead } from '@unhead/vue'
import { useToast } from 'vue-toast-notification'
import Popup from '@/components/Popup.vue'
import ImagePopup from '@/components/ImagePopup.vue'
import refresh from '@/assets/icons/refresh-2.svg'
import FooterComponent from '@/components/Footer.vue'
import premadeModels from 'common/lib/premade.js'
import generatorCategories from '@/configs/generator.js'
import { useStore } from 'vuex'
import cdnFile from '@/helpers/cdnFile'
import { useLoading } from 'vue-loading-overlay'
import { useRouter, useRoute } from 'vue-router'
import trialConfig from '@/configs/trial'
import { usePaymentNotifier } from '@/composables/usePaymentNotifier'
import { useUpdateQuery } from '@/composables/useUpdateQuery'
import image from '@/assets/icons/image.svg'
import imageUser from '@/assets/icons/image-user.svg'
import plus from '@/assets/icons/plus.svg'
import infoCircleFilled from '@/assets/icons/info-circle-filled.svg'
import arrowLeft from '@/assets/icons/arrow-left.svg'
import authParticles from '@/assets/img/auth-particles.svg'
import ButtonComponent from '../components/ButtonComponent.vue'
import AppHeader from '@/components/AppHeader.vue'
import { v4 as uuidv4 } from 'uuid'
import { createTimeElapsedCounter } from '@/helpers/timeElapsed'
import heart from '@/assets/icons/heart-black.svg'
import heartFilled from '@/assets/icons/heart-filled-black.svg'
import { useMixpanel } from '@/composables/mixpanel'
import unlock from '@/assets/icons/unlock.svg'
import checkmark from '@/assets/icons/checkmark.svg'
import xmark from '@/assets/icons/x-close.svg'
import { onMounted } from 'vue'
import { useUserStore } from '@/stores/user'

const headTitle = `${import.meta.env.VITE_BRAND_NAME} | Image Generator`
const headDescription =
  'Discover the future of adult entertainment with our Image Generator. Using artificial intelligence, this tool generates customizable and unique adult content safely and privately.'
const headUrl = `https://${import.meta.env.VITE_DOMAIN_NAME}/image-generator`
const headImage = `https://${import.meta.env.VITE_DOMAIN_NAME}/twitter-card.png`

const userStore = useUserStore()

const freeGeneratedImages = userStore.freeGeneratedImages

useHead({
  title: headTitle,
  meta: [
    { name: 'description', content: headDescription },

    { property: 'og:title', content: headTitle },
    { property: 'og:description', content: headDescription },
    { property: 'og:url', content: headUrl },
    { property: 'og:image', content: headImage },

    { name: 'twitter:title', content: headTitle },
    { name: 'twitter:description', content: headDescription },
    { name: 'twitter:url', content: headUrl },
    { name: 'twitter:card', content: 'summary' },
    { name: 'twitter:image', content: headImage },
  ],
})

let generatorSessionId = uuidv4()
let generatorSessionTimeElapsed = createTimeElapsedCounter()

const mixpanel = useMixpanel()

// let timeElapsedVC = () => {
//   if (document.visibilityState === 'hidden') {
//     mixpanel.track(
//       'generator_session_end',
//       {
//         generator_session_id: generatorSessionId,
//         generator_time_elapsed: generatorSessionTimeElapsed(),
//       },
//       { transport: 'sendBeacon' },
//     )
//   } else {
//     generatorSessionId = uuidv4()
//     generatorSessionTimeElapsed = createTimeElapsedCounter()
//     mixpanel.track(
//       'generator_page_visit',
//       {
//         generator_session_id: generatorSessionId,
//         generator_time_elapsed: generatorSessionTimeElapsed(),
//       },
//       { source: 'resume' },
//     )
//   }
// }

// document.addEventListener('visibilitychange', timeElapsedVC)

// onBeforeUnmount(() => {
// document.removeEventListener('visibilitychange', timeElapsedVC)
// mixpanel.track('generator_session_end', {
//   generator_session_id: generatorSessionId,
//   generator_time_elapsed: generatorSessionTimeElapsed(),
// })
// })

mixpanel.track(
  'generator_page_visit',
  {
    generator_session_id: generatorSessionId,
    generator_time_elapsed: generatorSessionTimeElapsed(),
  },
  { source: 'previous' },
)

const $axios = inject('axios')

const $toast = useToast()

const $router = useRouter()
const $route = useRoute()

const container = ref(null)

const $loading = useLoading()

// const nImagesOptions = [1, 4, 9, 16]
const nImagesOptions = [1, 4]

const imageGenerationCost = {
  1: 10,
  4: 30,
  9: 60,
  16: 90,
}

const modelSelectPopupOpen = ref(false)
function modelSelectPopupUpdated(val) {
  modelSelectPopupOpen.value = val
}

const modelSelectFilters = ref({
  section: null,
  style: null,
  gender: null,
})
const selectedTagsCategory = ref(generatorCategories[0].name)

const modelSelectFilterOptions = {
  section: [
    {
      name: 'Discover',
      value: 'premade',
    },
    {
      name: 'My AI',
      value: 'custom',
    },
  ],
  style: [
    {
      name: 'Realistic',
      value: 'realistic',
    },
    {
      name: 'Anime',
      value: 'anime',
    },
  ],
  gender: [
    {
      name: 'Female',
      value: 'female',
    },
    {
      name: 'Male',
      value: 'male',
    },
  ],
}

const $store = useStore()

const allModels = computed(() => {
  const models = []
  models.push(...premadeModels.map((a) => a.model))
  if ($store.getters.models) models.push(...$store.getters.models)
  return models
})

const shownModels = computed(() =>
  allModels.value.filter((model) => {
    if (modelSelectFilters.value.section == 'premade' && !model.premadeId) {
      return false
    }
    if (modelSelectFilters.value.section == 'custom' && model.premadeId) {
      return false
    }
    if (
      modelSelectFilters.value.style == 'realistic' &&
      model.style != 'realistic'
    ) {
      return false
    }
    if (modelSelectFilters.value.style == 'anime' && model.style != 'anime') {
      return false
    }
    if (
      modelSelectFilters.value.gender == 'female' &&
      model.gender != 'female'
    ) {
      return false
    }
    if (modelSelectFilters.value.gender == 'male' && model.gender != 'male') {
      return false
    }

    return true
  }),
)

const selectedModel = ref(null)

const prompt = ref('')
const nImages = ref(1)

const outputImages = ref([])

const firstGeneration = ref(null)

function clearGeneration() {
  outputImages.value = []
}

const outputImagesGridCols = computed(() => {
  if (outputImages.value.length == 1) {
    return 1
  }
  if (outputImages.value.length == 4) {
    return 2
  }
  if (outputImages.value.length == 9) {
    return 3
  }
  if (outputImages.value.length == 16) {
    return 4
  }
})

useUpdateQuery({
  variables: {
    modelId: computed(
      () => selectedModel.value?.id || selectedModel.value?.premadeId,
    ),
    prompt,
    nImages,
    outputImages: computed(() =>
      outputImages.value.map((img) => ({
        id: img.id,
        saved: img.saved,
        image: img.image,
      })),
    ),
    firstGeneration: computed(() => firstGeneration.value),
  },
  onUpdate() {
    $store.commit('UPDATE_LAST_APP_PAGE')
  },
  onLoad(queryVariables) {
    $store.commit('UPDATE_LAST_APP_PAGE')
    if (queryVariables.modelId) {
      selectedModel.value = allModels.value.find(
        (model) =>
          model.id == queryVariables.modelId ||
          model.premadeId == queryVariables.modelId,
      )
    }
    if (queryVariables.prompt) prompt.value = queryVariables.prompt
    if (queryVariables.nImages) nImages.value = parseInt(queryVariables.nImages)
    if (queryVariables.outputImages)
      outputImages.value = JSON.parse(queryVariables.outputImages)
    if (queryVariables.firstGeneration)
      firstGeneration.value = queryVariables.firstGeneration
  },
})

const selectedImageId = ref(null)
const selectedImage = computed(() =>
  !selectedImageId.value
    ? null
    : outputImages.value.find((img) => img.id == selectedImageId.value),
)
const imagePopupOpen = ref(false)
function openImagePopup(img) {
  selectedImageId.value = img.id
  imagePopupOpen.value = true
}
function imagePopupUpdated(val) {
  imagePopupOpen.value = val
}

function selectExistingCharacter() {
  mixpanel.track('generator_select_existing_model_click', {
    generator_session_id: generatorSessionId,
    generator_time_elapsed: generatorSessionTimeElapsed(),
  })

  modelSelectPopupOpen.value = true
}

function createNewCharacter() {
  mixpanel.track('generator_create_new_model', {
    generator_session_id: generatorSessionId,
    generator_time_elapsed: generatorSessionTimeElapsed(),
  })

  $router.push('/create-model?from=image-generator')
}

function changeSelectedCharacter() {
  mixpanel.track('generator_change_selected_model_click', {
    generator_session_id: generatorSessionId,
    generator_time_elapsed: generatorSessionTimeElapsed(),
  })

  modelSelectPopupOpen.value = true
}

function selectNumberOfImages(n) {
  mixpanel.track('generator_select_number_of_images', {
    generator_session_id: generatorSessionId,
    generator_time_elapsed: generatorSessionTimeElapsed(),
    n_images: n,
  })

  nImages.value = n
}

function selectFilter(filter, filterOption) {
  if (modelSelectFilters.value[filter] != filterOption) {
    mixpanel.track('generator_select_filter', {
      generator_session_id: generatorSessionId,
      generator_time_elapsed: generatorSessionTimeElapsed(),
      filter,
      filter_option: filterOption,
    })

    modelSelectFilters.value[filter] = filterOption
  } else {
    mixpanel.track('generator_select_filter', {
      generator_session_id: generatorSessionId,
      generator_time_elapsed: generatorSessionTimeElapsed(),
      filter,
      filter_option: null,
    })

    modelSelectFilters.value[filter] = null
  }
}

function selectModel(model) {
  mixpanel.track('generator_select_model', {
    generator_session_id: generatorSessionId,
    generator_time_elapsed: generatorSessionTimeElapsed(),
    model_id: model.id || model.premadeId,
    filters: modelSelectFilters.value,
  })

  selectedModel.value = model
  modelSelectPopupOpen.value = false
}

async function saveImage(id, val) {
  if (val) {
    mixpanel.track('save_image', {
      generator_session_id: generatorSessionId,
      generator_time_elapsed: generatorSessionTimeElapsed(),
      from: 'generator',
      image_id: id,
    })
  } else {
    mixpanel.track('unsave_image', {
      generator_session_id: generatorSessionId,
      generator_time_elapsed: generatorSessionTimeElapsed(),
      from: 'generator',
      image_id: id,
    })
  }

  outputImages.value.find((outputImage) => outputImage.id == id).saved = val
  await $axios
    .post('/user/save-image', {
      imageId: id,
      saved: val,
    })
    .catch((e) => {
      const message = e?.response?.data?.message
      $toast.error(
        message || 'Unable to perform action. Please try again later.',
      )
    })
}

function openLoginRegisterPopup() {
  $store.commit('SET_LOGIN_REGISTER_POPUP_OPEN', { open: true })
}

const errors = ref({
  character: false,
  prompt: false,
})

async function generateImage() {
  if (!selectedModel.value) {
    errors.value.character = true
    scrollTo(0, 0)
    $toast.error('Please select a character.')
    return
  }
  if (!prompt.value.trim()) {
    errors.value.prompt = true
    $toast.error('Please enter a prompt.')
    return
  }

  if (!user.value) {
    return openLoginRegisterPopup()
  }

  if (
    (!user.value.subscription &&
      user.value.images.length >= trialConfig.imagesCap &&
      freeGeneratedImages.length >= 2) ||
    (!user.value.subscription && user.value.cardLast4) ||
    user.value.trialExpired
  ) {
    return openSubscribePopup()
  }

  const lunaNeeded = imageGenerationCost[nImages.value]

  if (user.value.subscription && user.value.luna < lunaNeeded) {
    $store.commit('SET_NO_BALANCE_POPUP', {
      open: true,
    })
    return
  }

  const loader = $loading.show({
    container: container.value,
    canCancel: false,
    color: '#a15cff',
  })
  container.value.classList.add('generator-loader-wrapper')

  const generateImageTimeElapsed = createTimeElapsedCounter()

  mixpanel.track('generator_generate_start', {
    generator_session_id: generatorSessionId,
    generator_time_elapsed: generatorSessionTimeElapsed(),
    model_id: selectedModel.value.id || selectedModel.value.premadeId,
    n_images: nImages.value,
    prompt_length: prompt.value.length,
  })

  await $axios
    .post('/user/generate-image', {
      modelId: selectedModel.value.id || selectedModel.value.premadeId,
      prompt: prompt.value,
      n: nImages.value,
    })
    .catch((e) => {
      if (e?.response?.data?.csamBlock) {
        $store.dispatch('changeLuna', -lunaNeeded)
      }

      loader.hide()
      container.value.classList.remove('generator-loader-wrapper')
      const message = e?.response?.data?.message
      $toast.error(
        message || 'Unable to perform action. Please try again later.',
      )
    })
    .then((res) => {
      outputImages.value = res.data.images

      $store.commit('ADD_USER_IMAGES', res.data.images)

      if (freeGeneratedImages && freeGeneratedImages.length < 2) {
        userStore.addFreeGeneratedImage(res.data.images[0].id)
      }

      if (user.value.subscription) {
        $store.dispatch('changeLuna', -lunaNeeded)
      }

      loader.hide()
      container.value.classList.remove('generator-loader-wrapper')
      window.scrollTo({ top: 0 })
    })

  mixpanel.track('generator_generate_finish', {
    generator_session_id: generatorSessionId,
    generator_time_elapsed: generatorSessionTimeElapsed(),
    model_id: selectedModel.value.id || selectedModel.value.premadeId,
    n_images: nImages.value,
    prompt_length: prompt.value.length,
    generator_generate_time_elapsed: generateImageTimeElapsed(),
  })
}

const user = computed(() => $store.state.user)
const discountPopupOpen = computed(() => $store.state.discountPopupOpen)

function openSubscribePopup(includeText = true) {
  firstGeneration.value = true
  $store.commit('SET_SUBSCRIBE_POPUP', {
    open: true,
    ...(includeText && { text: 'generate more pics' }),
  })
}

function openBuyLuna() {
  $store.commit('SET_BUY_LUNA_POPUP', {
    open: true,
  })
}

function insertTag(tag) {
  const trimmedPrompt = prompt.value.trim()
  const promptEmpty = trimmedPrompt.length === 0
  const lastChar = trimmedPrompt.slice(-1)

  prompt.value =
    trimmedPrompt +
    (promptEmpty ? tag : lastChar === ',' ? ` ${tag}` : `, ${tag}`)
}

usePaymentNotifier(openSubscribePopup, openBuyLuna)

const guidePopupOpen = ref(false)

function openGuidePopupUpdated(val) {
  guidePopupOpen.value = val
}

function openGuidePopup() {
  mixpanel.track('generator_open_guide_popup', {
    generator_session_id: generatorSessionId,
    generator_time_elapsed: generatorSessionTimeElapsed(),
  })
  guidePopupOpen.value = true
}

onMounted(() => {
  if (firstGeneration.value) {
    firstGeneration.value = null
    const lunaNeeded = imageGenerationCost[nImages.value]
    $axios
      .post('/user/deduct-luna', {
        luna: lunaNeeded,
      })
      .catch((e) => {
        const message = e?.response?.data?.message
        $toast.error(
          message || 'Unable to perform action. Please try again later.',
        )
      })
      .then(() => {
        $store.dispatch('changeLuna', -lunaNeeded)
      })
  }
})
</script>

<template>
  <ImagePopup
    v-if="selectedImage"
    class="sticky z-50"
    :open="imagePopupOpen"
    :src="cdnFile(selectedImage.image)"
    :saved="selectedImage.saved"
    @update:open="imagePopupUpdated"
    @update:saved="(val) => saveImage(selectedImage.id, val)"
  />
  <div
    class="bg-[#070917] border-[#282828] lg:ml-[280px] relative pb-20"
    ref="container"
  >
    <div>
      <Popup
        :open="modelSelectPopupOpen"
        @update:open="modelSelectPopupUpdated"
        :close-button="false"
        popupStyle="h-[100vh] w-full lg:max-w-[1273px] lg:h-[95vh]"
      >
        <AppHeader class="lg:hidden" style="top: 0" />
        <div class="px-4 lg:px-[55px]">
          <div
            class="flex items-center justify-between mt-8 lg:mt-[1px] lg:mr-[1px] mb-5 lg:mb-[47px]"
          >
            <p class="text-[25px] lg:text-[36px] leading-1">Choose Character</p>
            <ButtonComponent
              @click="createNewCharacter"
              class="hidden lg:flex justify-center items-center gap-[11px] py-[11px] px-[14px]"
            >
              <div class="bg-[#141A3B] bg-opacity-15 p-[7px] rounded-[10px]">
                <img class="w-[18px] h-[18px]" :src="plus" alt="plus icon" />
              </div>
              Create New Character
            </ButtonComponent>
            <ButtonComponent
              variant="secondary"
              :borderVisible="false"
              class="flex items-center gap-[10px] p-[10px] lg:py-[15px] lg:pl-5 lg:pr-[30px] mr-[1px]"
              @click="modelSelectPopupUpdated(false)"
            >
              <img :src="arrowLeft" alt="back" />
              <span class="hidden lg:inline">Back</span>
            </ButtonComponent>
          </div>
          <div class="mx-[1px]">
            <ButtonComponent
              @click="createNewCharacter"
              class="flex justify-center items-center gap-[11px] py-[10px] w-full lg:hidden mb-5"
            >
              Create New Character
              <div class="bg-[#141A3B] bg-opacity-15 p-[7px] rounded-[10px]">
                <img class="w-[18px] h-[18px]" :src="plus" alt="plus icon" />
              </div>
            </ButtonComponent>
          </div>
          <div
            class="overflow-x-scroll grid grid-cols-3 gap-5 p-[7px] lg:p-0 lg:flex flex-row justify-between bg-[#0A0D22] lg:bg-transparent sm:gap-8 rounded-[15px] border lg:border-0 border-[#161929]"
          >
            <div
              v-for="filter of user
                ? Object.keys(modelSelectFilters)
                : Object.keys(modelSelectFilters).filter(
                    (key) => key != 'section',
                  )"
              :key="filter"
              class="flex flex-col lg:flex-row gap-2"
            >
              <div
                v-for="filterOption of modelSelectFilterOptions[filter]"
                :key="filterOption"
                class="flex h-[52px] justify-center items-center lg:w-[178px] py-[21px] bg-[#141939] cursor-pointer rounded-[15px] border border-[#141A3D] border-opacity-70"
                :style="
                  modelSelectFilters[filter] === filterOption.value
                    ? {
                        background:
                          'radial-gradient(255.14% 174.74% at 38.76% 155.71%, #CC47FF 0%, #9A5CFF 100%)',
                        boxShadow:
                          '0px 0px 0px 1px #B852FF, 0px -2px 0px 0px #A831FD inset',
                        border: '1px solid',
                        borderImageSource:
                          'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(153, 153, 153, 0.1) 100%)',
                      }
                    : { borderColor: 'transparent' }
                "
                @click="selectFilter(filter, filterOption.value)"
              >
                <p class="text-[14px]">{{ filterOption.name }}</p>
              </div>
            </div>
          </div>
          <div
            class="gallery-container grid grid-cols-2 lg:grid-cols-4 gap-5 mt-[33px] mb-[114px] lg:mb-0"
          >
            <div
              v-for="(shownModel, i) of shownModels"
              @click="selectModel(shownModel)"
              class="cursor-pointer"
            >
              <img
                :key="i"
                :src="cdnFile(shownModel.referenceImage)"
                :alt="shownModel.name"
                class="aspect-[832/1216] rounded-lg"
                style="user-select: none; user-drag: none; pointer-events: none"
                draggable="false"
              />
            </div>
          </div>
        </div>
      </Popup>
      <Popup
        :open="guidePopupOpen"
        @update:open="openGuidePopupUpdated"
        :unmount="false"
        popupStyle="h-[100vh] w-full lg:max-w-[1273px] lg:h-[95vh]"
      >
        <div class="px-4 lg:px-[55px]">
          <h2 class="text-[25px] lg:text-[36px] leading-1 mb-4 mt-2.5 lg:mt-0">
            How to Prompt
          </h2>

          <div class="leading-[1.8]">
            <p class="mb-2">
              In order to get the best results from the Image Generator, you
              need to follow a certain prompting style:
            </p>
            <ul class="list-disc pl-8 mb-2">
              <li>
                Enter your prompt in keywords instead of creating one sentence
              </li>
              <li>
                Avoid adding unnecessary phrases like 'show me ___' or 'create
                an image with ___'
              </li>
              <li>
                Avoid adding the character's name or any physical characterstics
                of the character that are already known
              </li>
            </ul>

            <p class="mb-6">
              Below are some examples to showcase optimal and suboptimal
              prompting styles:
            </p>

            <div class="flex flex-col gap-6">
              <div class="flex flex-col gap-1">
                <div class="flex gap-2 items-center">
                  <img :src="xmark" class="h-5 w-5" alt="Incorrect" />she is
                  smiling while wearing a dress at the club
                </div>
                <div class="flex gap-2 items-center">
                  <img
                    :src="checkmark"
                    class="h-5 w-5 p-0.5"
                    alt="Correct"
                  />smiling, wearing dress, club background
                </div>
              </div>

              <div class="flex flex-col gap-1">
                <div class="flex gap-2 items-center">
                  <img :src="xmark" class="h-5 w-5" alt="Incorrect" />
                  amara, african american, pool party background, hands up
                </div>
                <div class="flex gap-2 items-center">
                  <img
                    :src="checkmark"
                    class="h-5 w-5 p-0.5"
                    alt="Correct"
                  />pool party background, hands up
                </div>
              </div>

              <div class="flex flex-col gap-1">
                <div class="flex gap-2 items-center">
                  <img :src="xmark" class="h-5 w-5" alt="Incorrect" />
                  show her wearing a short jean skirt and a crop top at the
                  beach
                </div>
                <div class="flex gap-2 items-center">
                  <img
                    :src="checkmark"
                    class="h-5 w-5 p-0.5"
                    alt="Correct"
                  />wearing short jean skirt and crop top, beach background
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>
      <AppHeader>
        <template #title>
          <div class="hidden lg:block">
            <h1 class="text-[25px]">Image Generator</h1>
          </div>
        </template>
      </AppHeader>
      <div
        class="px-4 xl:px-16 3xl:mx-auto m-auto max-w-[1450px]"
        :class="discountPopupOpen ? 'mt-[90px] lg:mt-[64px]' : ''"
      >
        <button
          v-if="outputImages.length"
          type="button"
          class="absolute top-6 left-3 text-white lg:hidden focus:outline-none"
          @click="outputImages = []"
        >
          <span class="sr-only">Go Back</span>
          <ChevronLeftIcon class="h-6 w-6" aria-hidden="true" />
        </button>
        <div class="mb-[30px] lg:hidden">
          <h1 class="text-[25px]">Image Generator</h1>
        </div>
        <div
          class="lg:bg-[#0A0D22] bg-opacity-50 lg:pt-[24px] lg:pb-[34px] lg:px-[27px] lg:grid lg:grid-cols-1 xl:grid-cols-2 gap-10 lg:border rounded-[35px] border-[#141A3D] border-opacity-70"
        >
          <div
            v-if="!outputImages.length"
            class="relative bg-[#0D112A] bg-opacity-30 hidden lg:flex flex-col justify-center items-center gap-4 aspect-square overflow-hidden rounded-[25px] border border-[#141A3D] border-opacity-70"
          >
            <img
              :src="authParticles"
              class="absolute object-cover min-h-full w-[1185px] h-[656px] max-w-none left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2 select-none"
              style="-webkit-user-drag: none"
              alt=""
            />
            <div
              class="flex justify-center items-center bg-[#0A0E22] bg-opacity-50 w-[80px] h-[80px] rounded-[27px] border border-white border-opacity-5"
            >
              <img :src="image" alt="" />
            </div>
            <p class="max-w-[259px] text-[18px] text-center">
              Pictures you generate will be shown here
            </p>
          </div>
          <div v-else class="flex flex-col items-center gap-3">
            <div
              class="grid justify-center items-center aspect-[832/1216] rounded-lg border-2 border-[rgb(255,255,255,0.5)] overflow-hidden"
              :class="`grid-cols-${outputImagesGridCols}`"
            >
              <div
                v-for="outputImage of outputImages"
                :key="outputImage.id"
                class="relative"
              >
                <div
                  @click="
                    !user.subscription &&
                    user.images.length === 1 &&
                    !freeGeneratedImages.includes(outputImage.id)
                      ? openSubscribePopup(false)
                      : openImagePopup(outputImage)
                  "
                  @keydown="
                    !user.subscription &&
                    user.images.length === 1 &&
                    !freeGeneratedImages.includes(outputImage.id)
                      ? openSubscribePopup(false)
                      : openImagePopup(outputImage)
                  "
                  class="cursor-pointer"
                >
                  <img
                    :src="cdnFile(outputImage.image)"
                    class="aspect-[832/1216] object-cover w-full max-w-[832px]"
                    style="
                      user-select: none;
                      user-drag: none;
                      pointer-events: none;
                    "
                    draggable="false"
                    :style="
                      !user.subscription &&
                      user.images.length == 1 &&
                      !freeGeneratedImages.includes(outputImage.id)
                        ? 'filter: blur(8px) brightness(0.4); transform: scale(1.03)'
                        : ''
                    "
                    alt=""
                  />
                  <button
                    v-if="
                      !user.subscription &&
                      user.images.length == 1 &&
                      !freeGeneratedImages.includes(outputImage.id)
                    "
                    class="bg-purple-500 text-white rounded-md py-2 px-3 text-center text-sm leading-6 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex gap-2 items-center font-bold min-w-[105px]"
                  >
                    <img :src="unlock" alt="unlock icon" /> Unlock
                  </button>
                </div>

                <div class="absolute top-3 right-3 flex flex-col gap-3">
                  <MagnifyingGlassPlusIcon
                    class="h-8 text-black bg-white rounded-lg p-1.5 cursor-pointer"
                    @click.stop="
                      !user.subscription &&
                      user.images.length == 1 &&
                      freeGeneratedImages.includes(outputImage.id)
                        ? openSubscribePopup(false)
                        : openImagePopup(outputImage)
                    "
                  />
                  <img
                    v-if="!outputImage.saved"
                    :src="heart"
                    class="h-8 text-black bg-white rounded-lg p-1.5 cursor-pointer"
                    @click.stop="saveImage(outputImage.id, true)"
                    alt="heart icon"
                  />
                  <img
                    v-else
                    :src="heartFilled"
                    class="h-8 text-black bg-white rounded-lg p-1.5 cursor-pointer"
                    @click.stop="saveImage(outputImage.id, false)"
                    alt="heart filled icon"
                  />
                </div>
              </div>
            </div>
            <ButtonComponent
              variant="secondary"
              class="flex items-center gap-[10px] p-3 w-full justify-center lg:hidden"
              type="button"
              @click="clearGeneration"
            >
              <img :src="arrowLeft" alt="" />
              <span>Go Back</span>
            </ButtonComponent>
          </div>
          <div
            class="w-full flex flex-col gap-8 lg:gap-10"
            :class="{ 'hidden lg:flex': outputImages.length }"
          >
            <div
              v-if="!selectedModel"
              :class="{
                'bg-red-500 rounded-[20px]': errors.character,
              }"
              :style="
                errors.character
                  ? 'box-shadow: 0px 0px 0px 10px rgb(239, 68, 68)'
                  : ''
              "
              @click="errors.character = false"
            >
              <div class="lg:mt-[27px] mb-[35px] font-semibold">
                1. Choose Character
              </div>
              <div class="flex gap-[9px] mx-auto">
                <button
                  class="flex-1 bg-[#0A0D22] h-[157px] flex flex-col justify-center items-center gap-[21px] cursor-pointer rounded-[20px] border border-[#141A3D]"
                  @click="selectExistingCharacter"
                >
                  <img :src="imageUser" alt="user icon" />
                  <p class="text-center">Existing Character</p>
                </button>
                <button
                  @click="createNewCharacter"
                  class="flex-1 bg-[#0A0D22] h-[157px] flex flex-col justify-center items-center gap-[21px] cursor-pointer rounded-[20px] border border-[#141A3D]"
                >
                  <img :src="plus" alt="plus icon" />
                  <p class="text-center">Create New Character</p>
                </button>
              </div>
            </div>
            <div v-else>
              <div class="font-semibold lg:mt-[27px] mb-[35px]">
                1. Choose Character
              </div>
              <div class="flex gap-[9px] mx-auto">
                <button
                  class="flex-1 bg-[#0A0D22] h-[157px] flex flex-col justify-center items-center gap-[21px] cursor-pointer rounded-[20px] border border-[#141A3D]"
                  @click="selectExistingCharacter"
                >
                  <img
                    :src="cdnFile(selectedModel.referenceImage)"
                    :alt="selectedModel.name"
                    class="aspect-square rounded-full h-10 object-cover object-top"
                    style="
                      user-select: none;
                      user-drag: none;
                      pointer-events: none;
                    "
                    draggable="false"
                  />
                  <p class="text-center text-[14px]">
                    {{ selectedModel.name }}
                  </p>
                </button>
                <a
                  @click="changeSelectedCharacter"
                  class="flex-1 bg-[#0A0D22] h-[157px] flex flex-col justify-center items-center gap-[21px] cursor-pointer rounded-[20px] border border-[#141A3D]"
                >
                  <img :src="refresh" alt="refresh icon" />
                  <p class="text-center text-[14px]">Change</p>
                </a>
              </div>
            </div>
            <div
              class="border-t-0 border-r-0 border-l-0"
              style="
                width: 100%;
                height: 0px;
                border-width: 2px 0 0 0;
                border-image-source: linear-gradient(
                  90deg,
                  rgba(0, 0, 0, 0) 0%,
                  rgba(10, 13, 30, 0.78) 4.88%,
                  #13193c 55.51%,
                  rgba(8, 10, 23, 0.72) 95.52%,
                  rgba(0, 0, 0, 0) 100%
                );
                border-image-slice: 1;
              "
            ></div>
            <div
              :class="{
                'bg-red-500 rounded-[20px]': errors.prompt,
              }"
              :style="
                errors.prompt
                  ? 'box-shadow: 0px 0px 0px 10px rgb(239, 68, 68)'
                  : ''
              "
              @click="errors.prompt = false"
            >
              <div class="mb-[35px] font-semibold flex gap-3 items-center">
                2. Enter Prompt - what you'd like to see
                <img
                  @click="openGuidePopup"
                  class="h-5 w-5 cursor-pointer"
                  :src="infoCircleFilled"
                  alt="info icon"
                />
              </div>
              <textarea
                v-model="prompt"
                name="Prompt"
                rows="4"
                class="bg-[#0A0D22] placeholder-[#B1B5DB] py-[22px] px-[18px] rounded-[15px] border border-[#141A3D] w-full mx-auto resize-none"
                placeholder="Example: Long Sundress, Walking, Beach Background, Palm Trees"
              ></textarea>
              <div
                class="grid grid-cols-3 lg:flex gap-[6px] bg-[#0A0D22] my-[17px] p-[7px] rounded-[15px] border border-[#161929]"
              >
                <button
                  @click="selectedTagsCategory = category.name"
                  v-for="category in generatorCategories"
                  class="lg:flex-1 lg:bg-transparent flex justify-center items-center bg-[#141939] py-[13px] rounded-[10px] border border-transparent"
                  :class="category.name === selectedTagsCategory ? 'bg-[]' : ''"
                  :style="
                    category.name === selectedTagsCategory
                      ? {
                          background:
                            'radial-gradient(255.14% 174.74% at 38.76% 155.71%, #CC47FF 0%, #9A5CFF 100%)',
                          boxShadow:
                            '0px 0px 0px 1px #B852FF, 0px -2px 0px 0px #A831FD inset',
                          border: '1px solid',
                          borderImageSource:
                            'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(153, 153, 153, 0.1) 100%)',
                        }
                      : {}
                  "
                >
                  <p class="text-sm">{{ category.name }}</p>
                </button>
              </div>
              <div class="flex gap-[7px] flex-wrap">
                <button
                  v-for="tag in generatorCategories
                    .find((c) => c.name === selectedTagsCategory)
                    .tags.filter(
                      (t) => !prompt.toLowerCase().includes(t.toLowerCase()),
                    )"
                  class="flex items-center gap-[10px] bg-[#0E132D] p-1 pr-5 rounded-[100px] border border-[#141A3D] border-opacity-70"
                  @click="insertTag(tag)"
                >
                  <div class="bg-white bg-opacity-5 p-[7px] rounded-full">
                    <img
                      class="w-[18px] h-[18px]"
                      :src="plus"
                      alt="plus icon"
                    />
                  </div>
                  <p class="text-sm">{{ tag }}</p>
                </button>
              </div>
            </div>
            <div
              class="border-t-0 border-r-0 border-l-0"
              style="
                width: 100%;
                height: 0px;
                border-width: 2px 0 0 0;
                border-image-source: linear-gradient(
                  90deg,
                  rgba(0, 0, 0, 0) 0%,
                  rgba(10, 13, 30, 0.78) 4.88%,
                  #13193c 55.51%,
                  rgba(8, 10, 23, 0.72) 95.52%,
                  rgba(0, 0, 0, 0) 100%
                );
                border-image-slice: 1;
              "
            ></div>
            <div>
              <div class="mb-[35px] font-semibold">
                3. Select Number of Images
              </div>
              <div
                class="grid gap-[11px] lg:gap-[25px] grid-cols-4 sm:justify-items-center lg:justify-items-start"
              >
                <button
                  v-for="nImageOption of nImagesOptions"
                  :key="nImageOption"
                  class="relative flex justify-center items-center bg-[#0A0D22] max-w-[100px] sm:w-[100px] lg:w-[100px] aspect-square cursor-pointer rounded-[15px] border border-[#141A3D]"
                  :class="{
                    'bg-[rgb(255,255,255,0.3)] border-2 border-[rgb(255,255,255,0.3)]':
                      nImageOption == nImages,
                  }"
                  @click="
                    () =>
                      user && !user.subscription && nImageOption > 1
                        ? openSubscribePopup(false)
                        : selectNumberOfImages(nImageOption)
                  "
                >
                  <p class="text-[25px] font-semibold">{{ nImageOption }}</p>
                  <div
                    v-if="user && !user.subscription && nImageOption > 1"
                    class="text-[10px] font-extrabold absolute top-0 right-0 bg-[#D145FF] pl-[7px] pr-[10px] py-1 rounded-bl-[5.54px] rounded-tr-[5.54px]"
                  >
                    PRO
                  </div>
                </button>
              </div>
            </div>

            <div
              class="w-full flex flex-col lg:flex-row lg:items-center gap-[14px] lg:gap-0"
            >
              <div
                v-if="user && user.subscription"
                class="flex items-center gap-2"
              >
                <div class="flex items-center gap-[19px]">
                  <div
                    class="flex justify-center items-center bg-[#0A0D22] h-[60px] w-[60px] rounded-[15px] border border-[#141A3D]"
                  >
                    <img :src="infoCircleFilled" alt="info icon" />
                  </div>
                  <p class="text-[15px] lg:max-w-[259px]">
                    Generating this will consume <br />
                    <span class="font-semibold"
                      >{{ imageGenerationCost[nImages] }} Luna</span
                    >
                  </p>
                </div>
              </div>
              <ButtonComponent
                class="flex justify-center items-center w-full lg:w-[183px] ml-auto py-[14px]"
                @click="generateImage"
              >
                Generate
              </ButtonComponent>
            </div>
          </div>
        </div>
        <FooterComponent></FooterComponent>
      </div>
    </div>
  </div>
</template>

<style scoped>
@media screen and (min-width: 1280) {
  .gallery-container {
    display: grid;
    grid-template-columns: repeat(4, 276px);
  }
}

.explainer h2 {
  @apply text-[25px] mt-12 mb-[30px];
}

.explainer p {
  @apply text-[14px] text-[#B1B5DB] leading-[25px];
}

.explainer ul {
  @apply text-[#B1B5DB];
}

.generator-loader-wrapper {
  height: 100vh;
  overflow: hidden;
}
</style>
