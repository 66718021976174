import { defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  state: () => ({
    email: '',
    password: '',
    freeGeneratedImages: localStorage['user.freeGeneratedImages'] || [],
  }),
  actions: {
    setEmail(email) {
      this.email = email
    },
    setPassword(password) {
      this.password = password
    },
    addFreeGeneratedImage(imageId) {
      this.freeGeneratedImages.push(imageId)
    },
    setFreeGeneratedImages(imagesArray) {
      this.freeGeneratedImages = imagesArray
    },
  },

  persist: {
    storage: localStorage,
  },
})
